var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.queries.assetDimensionData.loading || !_vm.assetDimensionData ? _c('TalpaLoaderWrapper') : _c('PieChartWidget', {
    attrs: {
      "widgetHeader": true,
      "assetDimensionDetails": _vm.assetDimensionDetails,
      "assets": _vm.assets.length,
      "option": _vm.option
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }