<template>
  <PieChartStyled :header="widgetHeader">
    <WidgetHeaderMolecule :title="$t('widgetTypes.PIE_CHART')" :assetDimensions="assetDimensionDetails" v-if="widgetHeader" />
    <GraphStyled :hasNoData="hasNoData">
      <NoDataStyled v-if="hasNoData">{{ message }}</NoDataStyled>
      <PieChart :option="option" v-else />
    </GraphStyled>
  </PieChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import PieChart from '../Atoms/PieChart'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'

const PieChartStyled = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  ${({ header }) =>
    header
      ? header &&
        `
      grid-template-areas:
        "widgetHeader"
        "graph";
      grid-template-rows: 4rem 1fr;`
      : `grid-template-areas: "graph";
      grid-template-rows: 100%;`};
  grid-template-columns: 1fr;
`
const GraphStyled = styled('div')`
  grid-area: graph;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.25s ease;
  padding: 0.5rem;
  ${({ hasNoData }) => hasNoData && `display:flex; align-items: center; justify-content: center;`};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`
export default {
  inject: ['theme'],
  props: {
    option: {
      required: true,
      type: Object,
    },
    assetDimensionDetails: {
      required: false,
      type: Array,
    },
    assets: {
      required: false,
      type: Number,
    },
    widgetHeader: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    PieChart,
    PieChartStyled,
    GraphStyled,
    NoDataStyled,
    WidgetHeaderMolecule,
  },
  computed: {
    hasNoData() {
      const hasData = this.option?.series[0].data?.some(dp => dp.value !== undefined && dp.value !== null)
      return !hasData
    },
    message() {
      const dimensionNames = this.option?.series?.map(({ name }) => name)?.toString()
      return this.$t('messages.noDimensionData', { dimension: dimensionNames?.split(',').join(', ') })
    },
  },
}
</script>
