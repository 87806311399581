<template>
  <TalpaLoaderWrapper v-if="$apollo.queries.assetDimensionData.loading || !assetDimensionData" />
  <PieChartWidget v-else :widgetHeader="true" :assetDimensionDetails="assetDimensionDetails" :assets="assets.length" :option="option" />
</template>

<script>
import get from 'lodash/get'
import units from '@/utils/units'
import round from '@/utils/round'

import localesMixin from '@/mixins/locales'
import { DarkEChartTheme, LightEChartTheme } from '@styles/themes'

import PieChartWidget from '../../Molecules/PieChartWidget'
import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/data.gql'
import { TalpaLoaderWrapper } from '@common/components'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

export default {
  mixins: [localesMixin],
  inject: ['theme', 'uiSettings'],
  props: {
    assetDimension: {
      type: Object,
      required: true,
    },
    assets: {
      type: Array,
      required: true,
    },
    selectedTimeframeParam: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    PieChartWidget,
    TalpaLoaderWrapper,
  },
  filters: {
    round,
  },
  data() {
    return {
      assetDimensionData: null,
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    colors() {
      return this.theme.isDark ? DarkEChartTheme.color : LightEChartTheme.color
    },
    assetDimensionName() {
      return this.assetDimension?.name ?? null
    },
    assetIds() {
      return this.assets.map(({ id }) => id)
    },
    assetDimensionDetails() {
      const assetDimensionName = this.assetDimensionName
      const data = this.total
      const value =
        data !== null
          ? units(data, this.unitSI, this.unitUI, 2, false, false, false, this.thousandsSeperator, this.decimalSeperator, true)
          : '-'
      const unit = this.tooltipUnit
      return [
        {
          id: assetDimensionName,
          title: this.assetDimension?.nameTranslations
            ? getAssetDimensionNameByLocale(this.assetDimension?.nameTranslations, this.locale)
            : assetDimensionName,
          value,
          unit: unit,
          color: this.colors[0],
        },
      ]
    },
    total() {
      return this.assetDimensionData?.total ?? null
    },
    labels() {
      return this.assets.map(asset => asset.name)
    },
    unitSI() {
      return this.assetDimension?.physicalUnitSI ?? null
    },
    unitUI() {
      const unitUIMetric = this.assetDimension?.physicalUnitUIMetric ?? null
      const unitUIImperial = this.assetDimension?.physicalUnitUIImperial ?? null
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return unitUI
    },
    dataSets() {
      const aggr = get(this.assetDimensionData, 'aggrByAsset', [])
      const data = this.assets.map(asset => {
        const found = aggr.find(f => f.assetId === asset.id)
        const value = found && found.floatValue !== null ? this.convUnit(found.floatValue, this.unitSI, this.unitUI, 2, false) : null
        return {
          value: value,
          name: asset.name,
          tooltipValue: value ? this.numberLocalized(value) : '-',
          tooltipUnit: this.tooltipUnit,
        }
      })
      return data
    },
    tooltipUnit() {
      return this.unitUI ? this.unitUI.replace('mt', 't') : this.unitSI
    },
    piechartRadius() {
      const radius = {
        radius: this.isMobile ? ['40%', '80%'] : ['40%', '90%'],
        center: this.isMobile ? ['50%', '55%'] : ['40%', '50%'],
      }
      return radius
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `${params.data.name} : ${params.data.tooltipValue} ${params.data.tooltipUnit}`
          },
        },
        legend: {
          type: 'scroll',
          orient: this.isMobile ? 'horizontal' : 'vertical',
          top: 0,
          right: 0,
          bottom: 20,
          data: this.labels,
          animation: true,
          textStyle: {
            color: this.theme.colors.textActivePrimary,
          },
          pageIconColor: this.theme.colors.primaryActive,
          pageTextStyle: {
            color: this.theme.colors.textActivePrimary,
          },
        },
        series: [
          {
            name: this.assetDimension.nameTranslations
              ? getAssetDimensionNameByLocale(this.assetDimension.nameTranslations, this.locale)
              : this.assetDimensionName,
            type: 'pie',
            ...this.piechartRadius,
            data: this.dataSets,
            itemStyle: {
              borderWidth: 2,
              borderColor: this.theme.colors.solidBG,
            },
            label: {
              show: false,
              position: 'center',
            },
            avoidLabelOverlap: false,
            color: this.colors,
            animationType: 'scale',
            animationEasing: 'elasticOut',
          },
        ],
      }
    },
  },

  apollo: {
    assetDimensionData: {
      query: ASSET_DIMENSION_DATA_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: this.assetDimensionName,
            },
            timeframe: this.selectedTimeframeParam,
            assets: {
              id_in: this.assetIds,
            },
          },
        }
      },
      skip() {
        return !this.selectedTimeframeParam || !this.assetDimensionName || this.assets.length < 1
      },
    },
  },
}
</script>
