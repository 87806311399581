<template>
  <AssetDimensionPieChartWidget
    v-if="assetDimension && selectedTimeframeParam && assetsSelected"
    :assetDimension="assetDimension"
    :selectedTimeframeParam="selectedTimeframeParam"
    :assets="assetsSelected"
    :isMobile="isMobile"
  />
</template>

<script>
import get from 'lodash/get'

import { TimeframeMixin } from '@common/mixins'
import AssetDimensionPieChartWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionPieChartWidget'
import resizeMixin from '@/mixins/resize'
import { useAssetStore } from '@/stores/assets'

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [TimeframeMixin, resizeMixin],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetDimensionPieChartWidget,
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    dimensions() {
      const dimensions = get(this.widget, 'dimensions', [])
      return dimensions
    },
    assetDimension() {
      return get(this.dimensions[0], 'assetDimension', null)
    },
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        this.isMobile = size < 600 && size >= 96
      })
    },
  },
}
</script>
